"use client";
import Image from "next/image";
import Link from "next/link";
import LoginButton from "@/components/loginButton";
import { FormEvent, useEffect, useState } from "react";
import { signIn } from "next-auth/react";

export default function Page() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop as string),
    });
    //@ts-ignore
    if (params.error) {
      setError("Passwort oder Login ist inkorrekt");
      setTimeout(() => {
        setError("");
      }, 2500);
    }
  }, []);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await signIn("credentials", {
      password,
      email,
      redirect: true,
      callbackUrl: `${process.env.NEXT_PUBLIC_HOME_URL}/backend`,
    });
  };
  return (
    <div className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12 ">
      <Link href="/" className="my-6 flex items-center gap-x-1 lg:my-0">
        <Image src="logo.svg" alt="logo" width={150} height={150} />
      </Link>
      <div className="flex text-white rounded-lg border border-gray-200 bg-green-600 shadow-md flex-col md:max-w-xl md:flex-row w-full md:max-w-[1024px] [&amp;>img]:hidden md:[&amp;>img]:w-96 md:[&amp;>img]:p-0 md:[&amp;>*]:w-full md:[&amp;>*]:p-16 lg:[&amp;>img]:block">
        <img
          alt=""
          className="h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
          src="/login.jpg"
        />
        <div className="flex h-full w-full flex-col justify-center gap-4 p-6">
          <h1 className="mb-3 text-2xl font-bold  md:text-3xl">
            Login Bereich
          </h1>
          {error.length ? (
            <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
              {error}
            </span>
          ) : null}
          <form onSubmit={handleSubmit}>
            <div className="mb-4 flex flex-col gap-y-3">
              <label className="text-sm font-medium" htmlFor="email">
                Deine E-Mail Adresse
              </label>
              <div className="flex">
                <div className="relative w-full">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500 rounded-lg p-2.5 text-sm"
                    id="email"
                    name="email"
                    placeholder="dein@mail.addresse"
                    type="email"
                  />
                </div>
              </div>
            </div>
            <div className=" flex flex-col gap-y-3">
              <label className="text-sm font-medium" htmlFor="password">
                Dein Passwort
              </label>
              <div className="flex">
                <div className="relative w-full">
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500 rounded-lg p-2.5 text-sm"
                    id="password"
                    name="password"
                    placeholder="••••••••"
                    type="password"
                  />
                </div>
              </div>
            </div>
            <Link
              href="/passwort-vergessen"
              className="mb-6 mt-2 block text-sm text-right underline"
            >
              Passwort vergessen?
            </Link>
            <div className="mb-6">
              <button
                className="text-white bg-green-700 border border-transparent hover:bg-green-800 focus:ring-4 focus:ring-green-300 disabled:hover:bg-green-700 focus:!ring-2 p-0 font-medium rounded-lg w-full lg:w-auto"
                type="submit"
              >
                <span className="flex items-center rounded-md text-sm px-3 py-2">
                  Login
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
